import React from "react";

export const BurgerMenu = ({ onClick, className }) => {
  return (
    <div className={`BurgerMenu ${className}`} onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}