import React, { useContext } from "react";
import { ReactReduxContext } from "react-redux";
import holder from "../../assets/images/holder.webp";
import { Link } from "react-router-dom";
import { api_url } from "../../data";

export const NewsHome = () => {
  const { store } = useContext(ReactReduxContext);
  const state = store.getState().data.data;
  const posts = state.posts.slice(state.posts.length - 3, state.posts.length);

  function getDate(data) {
    if (data?.length > 0) {
      const date = new Date(data);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${day}.${month}.${year}`;
    } else {
      return "";
    }
  }

  return (
    <>
      <section className="wrapper retreat retreat--double fs-32 newsHome">
        <div className="newsHome__title">
          <div className="newsHome__title__name">
            <h2 className="fs-48 fw7 lh-120">Вместе мы уже помогли</h2>
            <p className="fs-20 fw4 lh-120">
              Принцип этой платформы — честность и прозрачность. Именно поэтому
              каждый волонтер предоставляет отчет по сбору и отправке помощи в
              зону СВО.
            </p>
          </div>
          <Link to={"/posts"} className="fs-20 fw5 btn btn--blue w-fit">
            Все отчеты
          </Link>
        </div>
        <div className="newsHome__grid">
          {posts.map((post, index) => (
            <div key={index} className="posts__grid__block">
              <div className="posts__grid__block__img">
                {post.image !== "null" ? (
                  <img src={api_url + post.image} alt={post.name} />
                ) : (
                  <img src={holder} alt={post.name} />
                )}
              </div>
              <div className="posts__grid__block__user">
                <div className="posts__grid__block__user__name">
                  <img src={api_url + post.user.avatar} alt={post.user.name} />
                  <p className="fs-14 fw3 lh-120">{post.user.name}</p>
                </div>
                <p className="fs-16 fw3">{getDate(post.created_at)}</p>
              </div>
              <div className="posts__grid__block__content">
                <p className="fs-18 fw5 lh-120">{post.name}</p>
                <p className="fs-14 fw3 lh-120">{post.description}</p>
              </div>
              <Link
                className="btn btn--blue fs-20 fw5"
                to={`/posts/${post.slug}`}
              >
                Прочитать
              </Link>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
