import React from "react";

import home from "../../assets/images/home.png";
import homePage from "../../assets/images/homePage.webp";
import { Link } from "react-router-dom";

export const SearchHome = ({ volunteers, collections }) => {
  return (
    <>
      <section
        className="wrapper wrapper--full homePage"
        style={{
          backgroundImage: `url(${homePage})`,
        }}
      >
        <div className="homePage__content wrapper">
          <div className="homePage__content__label btn btn--transparent btn--white w-fit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_778_6673)">
                <path
                  d="M5.0001 19H11.0001V21H7.0001C6.73488 21 6.48053 21.1053 6.29299 21.2929C6.10545 21.4804 6.0001 21.7348 6.0001 22C6.0001 22.2652 6.10545 22.5196 6.29299 22.7071C6.48053 22.8946 6.73488 23 7.0001 23H17.0001C17.2653 23 17.5197 22.8946 17.7072 22.7071C17.8947 22.5196 18.0001 22.2652 18.0001 22C18.0001 21.7348 17.8947 21.4804 17.7072 21.2929C17.5197 21.1053 17.2653 21 17.0001 21H13.0001V19H19.0001C20.1523 18.9985 21.2688 18.5999 22.1613 17.8713C23.0539 17.1427 23.668 16.1286 23.9001 15H0.100098C0.332194 16.1286 0.946278 17.1427 1.83885 17.8713C2.73142 18.5999 3.84789 18.9985 5.0001 19Z"
                  fill="#E65639"
                />
                <path
                  d="M19 1H5C3.67441 1.00159 2.40356 1.52888 1.46622 2.46622C0.528882 3.40356 0.00158786 4.67441 0 6L0 13H24V6C23.9984 4.67441 23.4711 3.40356 22.5338 2.46622C21.5964 1.52888 20.3256 1.00159 19 1Z"
                  fill="#E65639"
                />
              </g>
              <defs>
                <clipPath id="clip0_778_6673">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p className="fs-20 fw4 lh-120">Информационная платформа</p>
          </div>
          <h2 className="fs-48 fw7 lh-120">Найди свой способ помочь</h2>
          <p className="fs-20 fw6 lh-120">
            С «Помогаем.онлайн» вы вносите вклад в общее дело
          </p>
          <Link
            className="btn btn--blue w-fit fs-20 fw5 lh-120"
            to={"/collections"}
          >
            Как я могу помочь
          </Link>
        </div>
      </section>
      <section className="wrapper wrapper--full search">
        <div className="wrapper search__home">
          <div className="search__home__content">
            <h1 className="fs-40 fw7">О платформе</h1>
            <p className="fs-20 fw4 lh-120">
              «Помогаем.онлайн» — благотворительная платформа, созданная для
              поддержки российских военных. Перед нами стояла важная задача —
              связать неравнодушных граждан и волонтеров для сбора средств,
              когда это так необходимо.
            </p>
            <Link className="btn btn--blue w-fit" to={"/about"}>
              Узнайте больше о нас
            </Link>
          </div>
          <div className="search__home__img">
            <img src={home} alt="ДЛЯ СВОИХ" />
          </div>
        </div>
      </section>
    </>
  );
};
