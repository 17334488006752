import React, { useState } from "react";
import Modal from "react-modal";

export const QuestionModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // eslint-disable-next-line
  const Form = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    try {
      const response = await fetch("https://api.pomogaem.online/api/form", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        console.log("Данные успешно отправлены");
      } else {
        console.error("Ошибка при отправке данных");
      }
    } catch (error) {
      console.error("Ошибка при отправке запроса:", error);
    }
  };

  const modalContent = (
    <>
      <form action="https://api.pomogaem.online/api/form" method="POST">
        <h2 className="fs-32 fw2">Задать вопрос</h2>
        <label htmlFor="">
          <p className="fs-16 fw3">E-mail или телефон</p>
          <input
            className="fs-16 fw4"
            type="text"
            name="contact"
            placeholder="Поле ввода"
          />
        </label>
        <label htmlFor="">
          <p className="fs-16 fw3">Ваш вопрос</p>
          <textarea
            className="fs-16 fw4"
            type="text"
            name="question"
            placeholder="Поле ввода"
          />
        </label>
        <button className="btn btn--blue fs-20 fw5" type="submit">
          Отправить
        </button>
      </form>
      <button className="close--modal" onClick={closeModal}>
        <div className="button-close">
          <span></span>
          <span></span>
        </div>
      </button>
    </>
  );

  return (
    <>
      <section className="wrapper retreat">
        <div className="question__block">
          <h3 className="fs-20 fw6 lh-120">Не нашли вопрос?</h3>
          <p className="fs-20 fw4 lh-120">
            Задайте интересующий вопрос и мы обязательно ответим
          </p>
          <button onClick={openModal} className="btn btn--blue fs-20 fw5">
            Задать вопрос
          </button>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        className="modal"
      >
        {modalContent}
      </Modal>
    </>
  );
};
