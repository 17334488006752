import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import logo from "../logo.svg";
import logo from "../assets/images/logoBlack.png";
import login from "../assets/images/login.svg";
import { BurgerMenu } from "./components/BurgerMenu";
import { Navigation } from "./components/Navigation";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.querySelector("main").style.pointerEvents = "none";
    } else {
      document.body.style.overflow = "unset";
      document.querySelector("main").style.pointerEvents = "auto";
    }
  }, [isOpen]);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 1) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  });

  return (
    <header style={{ backgroundColor: isScroll ? "#fff" : "transparent" }}>
      <div
        onClick={() => setIsOpen(false)}
        className={`${
          isOpen
            ? "navigation--backdrop navigation--backdrop--open"
            : "navigation--backdrop"
        }`}
      ></div>
      <div className="wrapper header">
        <Link to="/" className="logo">
          <img src={logo} alt="ДЛЯ СВОИХ" />
        </Link>
        <Navigation
          isOpen={isOpen}
          className={`${isOpen ? "navigation--open" : ""}`}
        />
        <Link to="https://api.pomogaem.online/admin" className="login">
          <img src={login} alt="ДЛЯ СВОИХ" />
        </Link>
        <BurgerMenu
          onClick={toggleMenu}
          className={`${isOpen ? "open" : ""}`}
        />
      </div>
    </header>
  );
};
