import React, { useContext, useState } from "react";
import { Breadcrumbs } from "./components/Breadcrumbs";
import image from "../assets/images/postPage.webp";
import holder from "../assets/images/holder.webp";
import { ReactReduxContext } from "react-redux";
import { Link } from "react-router-dom";
import { api_url } from "../data";

export const Posts = () => {
  const { store } = useContext(ReactReduxContext);
  const state = store.getState();

  function getYear(date) {
    if (date?.length > 0) {
      const [year] = date.split("-");
      return `${year}`;
    } else {
      return "2024";
    }
  }

  const years = Array.from(
    new Set(
      state.data.data.posts.map((post) => getYear(post.created_at).toString())
    )
  )
    .sort()
    .reverse();

  function getDate(data) {
    if (data?.length > 0) {
      const date = new Date(data);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${day}.${month}.${year}`;
    } else {
      return "";
    }
  }

  const [postsDate, setPostsDate] = useState("За все время");

  return (
    <>
      <section className="wrapper retreat retreat--double fs-28">
        <Breadcrumbs breadcrumbs={[{ url: "posts", name: "Отчеты" }]} />
      </section>
      <section className="wrapper retreat about">
        <div
          className="about__img fs-40 fw7 lh-120"
          data-text="Честность от слова «честь»"
        >
          <img src={image} alt="Честность от слова «честь» " />
        </div>
        <div className="about__content retreat">
          <div className="about__content__block max-w-50">
            <h2 className="fs-32 fw7 lh-120">О чем эта страница</h2>
            <p className="fs-20 fw4 lh-120">
              Волонтеры платформы «Помогаем.онлайн» стремятся к максимальной
              прозрачности в своей деятельности. Они уверены, что граждане имеют
              право знать, как именно используются их средства.
            </p>
            <p className="fs-20 fw4 lh-120">
              На этой странице вы найдете отчеты обо всех закрытых сборах и их
              отправке на фронт.
            </p>
          </div>
        </div>
      </section>
      <section className="wrapper retreat posts">
        <div className="posts__select">
          <label className="btn btn--nav">
            <input
              type="radio"
              name="postsDate"
              value="За все время"
              checked={postsDate === "За все время"}
              onClick={() => setPostsDate("За все время")}
              id={`postsDate_all`}
              readOnly
              style={{ display: "none" }}
            />
            <p className="fs-20 fw5">За все время</p>
          </label>
          {years.length &&
            years.map((year, index) => (
              <label key={index} className="btn btn--nav">
                <input
                  type="radio"
                  name="postsDate"
                  value={year}
                  checked={postsDate === year}
                  onClick={() => {
                    setPostsDate(year);
                  }}
                  id={`postsDate_${year}`}
                  readOnly
                  style={{ display: "none" }}
                />
                <p className="fs-20 fw5">{year}</p>
              </label>
            ))}
        </div>
        <div className="posts__grid">
          {state.data.data.posts &&
            postsDate === "За все время" &&
            state.data.data.posts.map((post, index) => (
              <div key={index} className="posts__grid__block">
                <div className="posts__grid__block__img">
                  {post.image !== "null" ? (
                    <img src={api_url + post.image} alt={post.name} />
                  ) : (
                    <img src={holder} alt={post.name} />
                  )}
                </div>
                <div className="posts__grid__block__user">
                  <div className="posts__grid__block__user__name">
                    <img
                      src={api_url + post.user.avatar}
                      alt={post.user.name}
                    />
                    <p className="fs-14 fw3 lh-120">{post.user.name}</p>
                  </div>
                  <p className="fs-16 fw3">{getDate(post.created_at)}</p>
                </div>
                <div className="posts__grid__block__content">
                  <p className="fs-18 fw5 lh-120">{post.name}</p>
                  <p className="fs-14 fw3 lh-120">{post.description}</p>
                </div>
                <Link
                  className="btn btn--blue fs-20 fw5"
                  to={`/posts/${post.slug}`}
                >
                  Прочитать
                </Link>
              </div>
            ))}
          {state.data.data.posts &&
            postsDate !== "За все время" &&
            state.data.data.posts
              .filter((post) => getYear(post.created_at) === postsDate)
              .map((post, index) => (
                <div key={index} className="posts__grid__block">
                  <div className="posts__grid__block__img">
                    {post.image !== "null" ? (
                      <img src={api_url + post.image} alt={post.name} />
                    ) : (
                      <img src={holder} alt={post.name} />
                    )}
                  </div>
                  <div className="posts__grid__block__user">
                    <div className="posts__grid__block__user__name">
                      <img
                        src={api_url + post.user.avatar}
                        alt={post.user.name}
                      />
                      <p className="fs-14 fw3 lh-120">{post.user.name}</p>
                    </div>
                    <p className="fs-16 fw3">{getDate(post.created_at)}</p>
                  </div>
                  <div className="posts__grid__block__content">
                    <p className="fs-18 fw5 lh-120">{post.name}</p>
                    <p className="fs-14 fw3 lh-120">{post.description}</p>
                  </div>
                  <Link
                    className="btn btn--blue fs-20 fw5"
                    to={`/posts/${post.slug}`}
                  >
                    Прочитать
                  </Link>
                </div>
              ))}
        </div>
      </section>
    </>
  );
};
