import React, { useEffect, useState } from "react";
import { CollectionsGrid } from "./templates/CollectionsGrid";
import { Breadcrumbs } from "./components/Breadcrumbs";

export const Collections = ({ colTypePage }) => {
  let url = window.location.pathname.replace("/", "");

  const [ColVol, setColVol] = useState(colTypePage);

  useEffect(() => {
    url = window.location.pathname.replace("/", "");
    if (url === "voluteers") {
      setColVol("voluteers");
    }
    if (url === "collections") {
      setColVol("collections");
    }
  }, [url]);

  return (
    <>
      <section className="wrapper retreat retreat--double fs-24">
        <Breadcrumbs breadcrumbs={[{ url: url, name: "Сборы" }]} />
        <CollectionsGrid colTypePage={ColVol} />
      </section>
    </>
  );
};
