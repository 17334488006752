import React, { useContext } from "react";
import { ReactReduxContext } from "react-redux";
import { Breadcrumbs } from "./components/Breadcrumbs";
import Markdown from "react-markdown";

export const Privacy = () => {
  const { store } = useContext(ReactReduxContext);
  const state = store.getState().data.data;

  return (
    <>
      <section className="wrapper privacyPage">
        <Breadcrumbs
          breadcrumbs={[
            { url: "privacy", name: "Политика конфиденциальности" },
          ]}
        />
        <h3 className="fs-48 fw7 lh-120">Политика конфиденциальности</h3>
        <Markdown className="retreat markdown privacy">
          {state.privacy.text}
        </Markdown>
      </section>
    </>
  );
};
