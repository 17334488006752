import React, { useContext } from "react";
import { Breadcrumbs } from "./components/Breadcrumbs";
import image from "../assets/images/aboutPage.webp";
import { ReactReduxContext } from "react-redux";
import { QuestionModal } from "./components/QuestionModal";

export const AboutPage = () => {
  const { store } = useContext(ReactReduxContext);
  const state = store.getState();

  return (
    <>
      <section className="wrapper retreat retreat--double fs-28">
        <Breadcrumbs breadcrumbs={[{ url: "about", name: "О нас" }]} />
      </section>
      <section className="wrapper retreat about">
        <div
          className="about__img fs-40 fw7 lh-120"
          data-text="«Помогаем.онлайн» — вносим вклад в общее дело"
        >
          <img
            src={image}
            alt="«Помогаем.онлайн» — вносим вклад в общее дело"
          />
        </div>
        <div className="about__content retreat">
          <div className="about__content__block">
            <h2 className="fs-48 fw7 lh-120">О нас </h2>
            <p className="fs-20 fw4 lh-120">
              Общая цель объединила на нашей платформе представителей самых
              разных сфер: предприниматели, финансисты, рекламщики, инвесторы,
              писатели. И все они помогают онлайн, обеспечивая всестороннюю
              поддержку защитникам России и их семьям в сегодняшних реалиях.
            </p>
          </div>
          <div className="about__content__block">
            <h2 className="fs-32 fw7 lh-120">Миссия</h2>
            <p className="fs-20 fw4 lh-120">
              Миссия платформы «Помогаем.онлайн» — связать всех, кто хочет
              помочь солдатам и внести вклад в общую победу. Мы стремимся стать
              узнаваемым общественным движением, чтобы каждая наша акция была
              символом единства.
            </p>
          </div>
          <div className="about__content__block">
            <h2 className="fs-32 fw7 lh-120">Ценности </h2>
            <p className="fs-20 fw4 lh-120">
              Волонтеры и благотворители платформы «Помогаем.онлайн» верят, что
              оказание поддержки тем, кто сейчас находится на передовой, — наш
              долг.
            </p>
            <p className="fs-20 fw4 lh-120">
              Наша помощь выражается не только словами, но и действиями:
              волонтеры собирают и отправляют оборудование и экипировку,
              медикаменты, строительные инструменты и прочие предметы быта.
            </p>
          </div>
        </div>
      </section>
      <section className="wrapper about__faq">
        <h2 className="fs-32 fw7 lh-120">Часто задаваемые вопросы</h2>
        <div className="about__faq__grid retreat">
          {state.data.data.FAQ.map((item, index) => (
            <div className="about__faq__grid__block" key={index}>
              <p className="fs-20 fw6 lh-120">{item.question}</p>
              <p className="fs-20 fw4 lh-120">{item.answer}</p>
            </div>
          ))}
        </div>
      </section>
      <QuestionModal />
    </>
  );
};
