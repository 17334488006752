import React from "react";
import { Link } from "react-router-dom";

export const RouteHome = () => {
  return (
    <>
      <section className="wrapper retreat fs-24 routeHome">
        <h3 className="fs-48 fw7 lh-120">Как вы можете помочь?</h3>
        <p className="fs-20 fw4">
          Есть очень много разных способов. Пожертвование — только один из них.
          Выберите удобный вам формат.
        </p>
        <div className="routeHome__route retreat fs-12">
          <ul className="fs-48 fw6">
            <li>Выберете сбор из списка</li>
            <li>Свяжитесь с волонтером напрямую</li>
            <li>Окажите поддержку нашим солдатам</li>
          </ul>
          <Link to={"/collections"} className="fs-20 fw5 btn btn--blue w-fit">
            Посмотреть сборы
          </Link>
        </div>
      </section>
    </>
  );
};
