import React from "react";
import secret from "../assets/images/krysa.jpg";

export const Secret = () => {
  return (
    <>
      <section className="wrapper secret__wrapper">
        <img
          src={secret}
          alt="@izvenyaisya"
          className="secret__img retreat retreat--double"
        />
      </section>
    </>
  );
};
