import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import age from "../assets/images/18.svg";
import { Navigation } from "./components/Navigation";

export const Footer = () => {
  return (
    <>
      <footer className="retreat retreat--double">
        <div className="footer wrapper">
          <Navigation />
          <div className="footer__question">
            <p className="fs-20 fw4 lh-120">
              По вопросам пишите на{" "}
              <a className="fs-20 fw6 lh-120 mail" href="mailto:mail@mail.ru">
                mail@mail.ru
              </a>
            </p>
            <Link to={"/privacy"}>Политика конфиденциальности</Link>
          </div>
          <div className="footer__platform">
            <div className="footer__platform__logo">
              <p className="fs-20 fw4 lh-120">
                ©2024. Информационная платформа
              </p>
              <img src={logo} alt="ДЛЯ СВОИХ" />
            </div>
            <img src={age} alt="18+" />
          </div>
        </div>
      </footer>
    </>
  );
};
