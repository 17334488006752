import React, { useEffect, useState } from "react";

export const Loader = ({ active }) => {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  if (!active) {
    setTimeout(() => {
      setIsActive(false);
    }, 1000);
  }

  return (
    <div
      className={`loader__wrapper ${isActive ? "loader__wrapper--active" : ""}`}
    >
      <div className="loader">
        <svg viewBox="0 0 80 80">
          <circle id="test" cx="40" cy="40" r="32"></circle>
        </svg>
      </div>

      <div className="loader triangle">
        <svg viewBox="0 0 86 80">
          <polygon points="43 8 79 72 7 72"></polygon>
        </svg>
      </div>

      <div className="loader">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
        </svg>
      </div>
    </div>
  );
};
