import React from "react";
import { Link, useLocation } from "react-router-dom";

export const Navigation = ({ className }) => {
  const location = useLocation();

  const isActiveLink = (path) => {
    const pathnameParts = location.pathname.split("/");
    const pathParts = path.split("/");

    if (pathParts.includes("*")) {
      const wildcardIndex = pathParts.findIndex((part) => part === "*");
      if (wildcardIndex > -1) {
        const wildcardPathParts = pathParts.slice(wildcardIndex + 1);
        const missingParts = pathnameParts
          .slice(wildcardPathParts.length)
          .filter(Boolean);
        return missingParts.length === 0;
      }
    }

    if (
      pathnameParts.length > pathParts.length &&
      pathnameParts.slice(0, pathParts.length).join("/") === path
    ) {
      return true;
    }

    if (location.pathname === path) {
      return true;
    }

    return false;
  };

  return (
    <nav className={`navigation ${className}`}>
      <ul>
        <li>
          <Link
            to="/"
            className={isActiveLink("/") ? "fs-18 fw3 active" : "fs-18 fw3"}
          >
            Главная
          </Link>
        </li>
        <li>
          <Link
            to="/collections"
            className={
              isActiveLink("/collections") ? "fs-18 fw3 active" : "fs-18 fw3"
            }
          >
            Сборы
          </Link>
        </li>
        <li>
          <Link
            to="/volunteers"
            className={
              isActiveLink("/volunteers") ? "fs-18 fw3 active" : "fs-18 fw3"
            }
          >
            Волонтеры
          </Link>
        </li>
        <li>
          <Link
            to="/posts"
            className={
              isActiveLink("/posts") ? "fs-18 fw3 active" : "fs-18 fw3"
            }
          >
            Отчеты
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={
              isActiveLink("/about") ? "fs-18 fw3 active" : "fs-18 fw3"
            }
          >
            О нас
          </Link>
        </li>
        <li>
          <Link
            to="/information"
            className={
              isActiveLink("/information") ? "fs-18 fw3 active" : "fs-18 fw3"
            }
          >
            Полезная информация
          </Link>
        </li>
      </ul>
    </nav>
  );
};
