import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { api_url } from "../data";
import FancyBox from "./templates/FancyBox";
import Markdown from "react-markdown";
import { Loader } from "./components/loader";

export const Post = () => {
  const location = useLocation();

  const [post, setPost] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${api_url}/api${location.pathname}`)
      .then((response) => response.json())
      .then((data) => {
        setPost(data);
      })
      .catch((error) => {
        navigate("/404");
        console.error(error);
      });
    // eslint-disable-next-line
  }, []);

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsActive(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [post]);

  return (
    <>
      <Loader active={isActive} />
      {post && (
        <>
          <section className="wrapper retreat retreat--double fs-28">
            <Breadcrumbs
              breadcrumbs={[
                { url: "posts", name: "Отчеты" },
                { url: post.slug, name: post.name },
              ]}
            />
          </section>
          {(post.image !== "null" || post.images.length > 0) && (
            <section className="wrapper retreat post">
              <FancyBox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                {post.image !== "null" && (
                  <a data-fancybox="gallery" href={api_url + post.image}>
                    <img src={api_url + post.image} alt={post.name} />
                    <img
                      width="24"
                      height="24"
                      src="https://img.icons8.com/material-outlined/24/full-screen--v1.png"
                      alt="full-screen--v1"
                      className="fancybox__fullscreen-icon"
                    />
                  </a>
                )}
                {post.images.map((image, index) => (
                  <a data-fancybox="gallery" key={index} href={api_url + image}>
                    <img src={api_url + image} alt={post.name} />
                  </a>
                ))}
              </FancyBox>
            </section>
          )}
          <section className="wrapper retreat post__content">
            <h2 className="fs-40 fw7 lh-120">{post.name}</h2>
            <Markdown className="retreat markdown">{post.text}</Markdown>
          </section>
        </>
      )}
    </>
  );
};
