import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { api_url } from "../data";
import { Loader } from "./components/loader";
import toast, { Toaster } from "react-hot-toast";
import { useClipboard } from "use-clipboard-copy";
import banner from "../assets/images/banner_profile.png";
import holder from "../assets/images/holder.webp";
import tg from "../assets/images/tg.svg";
import wa from "../assets/images/wa.png";
import vk from "../assets/images/vk.svg";
import FancyBox from "./templates/FancyBox";

export const Volunteer = () => {
  function getYear(date) {
    if (date?.length > 0) {
      const [year] = date.split("-");
      return `${year}`;
    } else {
      return "2024";
    }
  }

  const navigate = useNavigate();

  function getDateZ(data) {
    if (data?.length > 0) {
      const date = new Date(data);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${day}.${month}.${year}`;
    } else {
      return "";
    }
  }

  const [postsDate, setPostsDate] = useState("За все время");

  const [RadioType, setRadioType] = useState("Аккаунт");

  const clipboard = useClipboard();
  function notify(text) {
    toast(`"${text}" было скопировано в буфер обмена!`);
  }

  const [isTop, setIsTop] = useState(false);
  const isSmallScreen = window.matchMedia("(min-width: 1140px)").matches;

  useEffect(() => {
    setIsTop(!isSmallScreen);
  }, [isSmallScreen]);

  function getDate(date) {
    if (date?.length > 0) {
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    } else {
      return "";
    }
  }

  const years = () => {
    return Array.from(
      new Set(post?.posts?.map((post) => getYear(post.created_at).toString()))
    )
      .sort()
      .reverse();
  };

  const location = useLocation();

  const [post, setPost] = useState(null);

  useEffect(() => {
    setIsActive(true);
    setPost(null);
    fetch(`${api_url}/api${location.pathname}`)
      .then((response) => response.json())
      .then((data) => {
        setPost(data);
      })
      .catch((error) => {
        navigate("/404");
        console.error(error);
      });
    // eslint-disable-next-line
  }, [location.pathname]);

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsActive(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [post]);

  function copyToClipboard(text) {
    clipboard.copy(text);
    notify(text);
  }

  const radio = [
    {
      name: "Аккаунт",
      id: "Account",
    },
    {
      name: "Записи волонтера",
      id: "Posts_of_Vol",
    },
    {
      name: "Сборы",
      id: "Collections",
    },
    {
      name: "Отчеты по сборам",
      id: "Posts",
    },
  ];

  return (
    <>
      <Toaster position={isTop ? "top-center" : "bottom-right"} />
      <Loader active={isActive} />
      {post && (
        <>
          <section className="wrapper retreat retreat--double fs-28">
            <Breadcrumbs
              breadcrumbs={[
                { url: `volunteers`, name: "Волонтеры" },
                { url: `${post.id}`, name: post.name },
              ]}
            />
          </section>
          <section className="wrapper retreat volunteerPage">
            <img
              className="volunteerPage__banner"
              src={post.profile_banner ? api_url + post.profile_banner : banner}
              alt={post.name}
            />
            <div className="volunteerPage__info">
              <div className="volunteerPage__info__name">
                <img src={api_url + post.avatar} alt={post.name} />
                <div className="volunteerPage__info__name__title">
                  <h1 className="fs-24 fw6">{post.name}</h1>
                  <p className="fs-20 fw4">Аккредитованный волонтер</p>
                </div>
              </div>
              <div className="volunteerPage__info__buttons">
                {post.link_contact && post.link_contact !== null && (
                  <a
                    href={post.link_contact}
                    className="fs-20 fw5 btn btn--blue"
                  >
                    Связаться с волонтером
                  </a>
                )}
                {/* <button className="fs-20 fw5 btn btn--transparent">
                  Подписаться
                </button> */}
              </div>
            </div>
            <div className="volunteerPage__list scrollBar">
              {radio.map((radio, index) => (
                <label key={index}>
                  <input
                    type="radio"
                    name="radioType"
                    value={radio.id}
                    checked={RadioType === radio.name}
                    onClick={() => setRadioType(radio.name)}
                    id={`radioType_${radio.id}`}
                    readOnly
                    style={{ display: "none" }}
                  />
                  <p className="fs-20 fw5">{radio.name}</p>
                </label>
              ))}
            </div>
            {RadioType === "Аккаунт" && (
              <div className="volunteerPage__account retreat">
                <div className="volunteerPage__account__title">
                  <h2 className="fs-24 fw7">О волонтере</h2>
                  <p className="fs-20 fw4">{post.description}</p>
                </div>
                {(post.categories && post.categories.length > 0) ||
                (post.locations && post.locations.length > 0) ? (
                  <div className="volunteerPage__account__info retreat">
                    {post.categories.length > 0 && (
                      <div className="volunteerPage__account__info__directions">
                        <h2 className="fs-20 fw6">Направления волонтера</h2>
                        <div className="volunteerPage__account__info__directions__list">
                          {post.categories.map((category, index) => (
                            <p key={index} className="fs-16 fw3 btn btn--nav">
                              {category.name}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                    {post.locations.length > 0 && (
                      <div className="volunteerPage__account__info__directions">
                        <h2 className="fs-20 fw6">В каких регионах работаем</h2>
                        <div className="volunteerPage__account__info__directions__list">
                          {post.locations.map((location, index) => (
                            <p key={index} className="fs-16 fw3 btn btn--nav">
                              {location.name}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
                {post.bills && post.bills.length > 0 && (
                  <div className="volunteerPage__account__bills retreat">
                    <h2 className="fs-32 fw7">Реквизиты</h2>
                    <div className="collectionPage__bills__grid scrollBar retreat">
                      {post.bills.map((row, index) => (
                        <div
                          key={index}
                          className="collectionPage__bills__grid__row"
                        >
                          {Object.keys(row.bill).map((key, index) => (
                            <div
                              key={index}
                              className="collectionPage__bills__grid__row__block"
                              onClick={() => {
                                copyToClipboard(row.bill[key].value);
                              }}
                            >
                              <p className="fs-20 fw4">
                                {row.bill[key].caption}
                              </p>
                              <p className="fs-24 fw3">{row.bill[key].value}</p>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="volunteerPage__account__bills retreat">
                  <h2 className="fs-32 fw7">Контакты</h2>
                  <div className="collectionPage__bills__grid scrollBar retreat">
                    <div className="collectionPage__bills__grid__row">
                      {post.phone !== null &&
                        post.phone &&
                        post.phone.length > 0 && (
                          <div
                            className="collectionPage__bills__grid__row__block contacts"
                            onClick={() => {
                              copyToClipboard(post.phone);
                            }}
                          >
                            <p className="fs-20 fw4">Номер для связи</p>
                            <a href={`tel:${post.phone}`} className="fs-24 fw3">
                              {post.phone}
                            </a>
                          </div>
                        )}

                      {post.telegram && post.telegram !== null && (
                        <div
                          className="collectionPage__bills__grid__row__block contacts"
                          onClick={() => {
                            copyToClipboard(post.telegram);
                          }}
                        >
                          <img src={tg} alt="Telegram" />
                          <a href={post.telegram} className="fs-24 fw3">
                            {post.telegram}
                          </a>
                        </div>
                      )}

                      {post.vk && post.vk !== null && (
                        <div
                          className="collectionPage__bills__grid__row__block contacts"
                          onClick={() => {
                            copyToClipboard(post.vk);
                          }}
                        >
                          <img src={vk} alt="VK" />
                          <a href={`tel:${post.vk}`} className="fs-24 fw3">
                            {post.vk}
                          </a>
                        </div>
                      )}

                      {post.whatsapp && post.whatsapp !== null && (
                        <div
                          className="collectionPage__bills__grid__row__block contacts"
                          onClick={() => {
                            copyToClipboard(post.telegram);
                          }}
                        >
                          <img src={wa} alt="WhatsApp" />
                          <a href={post.telegram} className="fs-24 fw3">
                            {post.telegram}
                          </a>
                        </div>
                      )}

                      {post.email && post.email !== null && (
                        <div
                          className="collectionPage__bills__grid__row__block contacts"
                          onClick={() => {
                            copyToClipboard(post.email);
                          }}
                        >
                          <p className="fs-20 fw4">Почта</p>
                          <a
                            href={`mailto:${post.email}`}
                            className="fs-24 fw3"
                          >
                            {post.email}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {RadioType === "Сборы" && post.collections.length > 0 && (
              <div className="collectionPage__other retreat">
                <h2 className="fs-32 fw7">Сборы волонтера</h2>
                <div className="collectionPage__other__flex scrollBar">
                  {post.collections.map((value, index) => (
                    <div className="collections__grid__block" key={index}>
                      <div
                        className="collections__grid__block__img"
                        style={{
                          backgroundImage: `url(${api_url + value.image})`,
                        }}
                      ></div>
                      <div className="collections__grid__block__volunteer">
                        <img src={api_url + post.avatar} alt="" />
                        <p className="fs-14 fw3 lh-120">{post.name}</p>
                      </div>
                      <h5 className="fs-18 fw5">{value.name}</h5>
                      <p className="fs-14 fw3">{value.description}</p>
                      <div className="collections__grid__block__progress">
                        <progress value={value.progress} max="100" />
                        <div className="collections__grid__block__progress__dates">
                          <p className="fs-14 fw3">
                            {getDate(value.start_date)}
                          </p>
                          <p className="fs-14 fw3">{getDate(value.end_date)}</p>
                        </div>
                      </div>
                      <div className="collections__grid__block__links">
                        <Link
                          className="btn btn--blue"
                          to={`/collections/${value.id}`}
                        >
                          Перейти на сбор
                        </Link>
                        <Link
                          className="btn btn--circle btn--blue"
                          to={`/volunteers/${value.id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="24"
                            viewBox="0 0 16 24"
                            fill="none"
                          >
                            <path
                              d="M7.45224 14.0182C3.19051 14.4034 -0.0563382 18.0045 0.000239928 22.2832V22.5002C0.000239928 23.3286 0.671818 24.0002 1.50024 24.0002C2.32866 24.0002 3.00024 23.3286 3.00024 22.5002V22.2232C2.95515 19.5961 4.8939 17.3558 7.50024 17.0232C10.2515 16.7504 12.703 18.7596 12.9758 21.5108C12.9919 21.6735 13.0001 21.8368 13.0002 22.0002V22.5002C13.0002 23.3286 13.6718 24.0002 14.5002 24.0002C15.3286 24.0002 16.0002 23.3286 16.0002 22.5002V22.0002C15.9953 17.5769 12.4056 13.9951 7.9824 14C7.80554 14.0002 7.62872 14.0063 7.45224 14.0182Z"
                              fill="white"
                            />
                            <path
                              d="M8.00049 12C11.3142 12 14.0005 9.31369 14.0005 6C14.0005 2.68631 11.3142 0 8.00049 0C4.6868 0 2.00049 2.68631 2.00049 6C2.00377 9.31233 4.68816 11.9967 8.00049 12ZM8.00049 3C9.65733 3 11.0005 4.34316 11.0005 6C11.0005 7.65684 9.65733 9 8.00049 9C6.34364 9 5.00049 7.65684 5.00049 6C5.00049 4.34316 6.34364 3 8.00049 3Z"
                              fill="white"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {RadioType === "Отчеты по сборам" && post.posts.length > 0 && (
              <>
                <div className="posts__select retreat">
                  <label className="btn btn--nav">
                    <input
                      type="radio"
                      name="postsDate"
                      value="За все время"
                      checked={postsDate === "За все время"}
                      onClick={() => setPostsDate("За все время")}
                      id={`postsDate_all`}
                      readOnly
                      style={{ display: "none" }}
                    />
                    <p className="fs-20 fw5">За все время</p>
                  </label>
                  {years().length > 0 &&
                    years().map((year, index) => (
                      <label key={index} className="btn btn--nav">
                        <input
                          type="radio"
                          name="postsDate"
                          value={year}
                          checked={postsDate === year}
                          onClick={() => {
                            setPostsDate(year);
                          }}
                          id={`postsDate_${year}`}
                          readOnly
                          style={{ display: "none" }}
                        />
                        <p className="fs-20 fw5">{year}</p>
                      </label>
                    ))}
                </div>
                <div className="posts__grid retreat">
                  {post.posts &&
                    postsDate === "За все время" &&
                    post.posts.map((value, index) => (
                      <div key={index} className="posts__grid__block">
                        <div className="posts__grid__block__img">
                          {value.image !== "null" ? (
                            <img src={api_url + value.image} alt={value.name} />
                          ) : (
                            <img src={holder} alt={value.name} />
                          )}
                        </div>
                        <div className="posts__grid__block__user">
                          <div className="posts__grid__block__user__name">
                            <img src={api_url + post.avatar} alt={post.name} />
                            <p className="fs-14 fw3 lh-120">{post.name}</p>
                          </div>
                          <p className="fs-16 fw3">
                            {getDateZ(value.created_at)}
                          </p>
                        </div>
                        <div className="posts__grid__block__content">
                          <p className="fs-18 fw5 lh-120">{value.name}</p>
                          <p className="fs-14 fw3 lh-120">
                            {value.description}
                          </p>
                        </div>
                        <Link
                          className="btn btn--blue fs-20 fw5"
                          to={`/posts/${value.slug}`}
                        >
                          Прочитать
                        </Link>
                      </div>
                    ))}
                  {post.posts &&
                    postsDate !== "За все время" &&
                    post.posts
                      .filter(
                        (value) => getYear(value.created_at) === postsDate
                      )
                      .map((value, index) => (
                        <div key={index} className="posts__grid__block">
                          <div className="posts__grid__block__img">
                            {value.image !== "null" ? (
                              <img
                                src={api_url + value.image}
                                alt={value.name}
                              />
                            ) : (
                              <img src={holder} alt={value.name} />
                            )}
                          </div>
                          <div className="posts__grid__block__user">
                            <div className="posts__grid__block__user__name">
                              <img
                                src={api_url + post.avatar}
                                alt={post.name}
                              />
                              <p className="fs-14 fw3 lh-120">{post.name}</p>
                            </div>
                            <p className="fs-16 fw3">
                              {getDateZ(value.created_at)}
                            </p>
                          </div>
                          <div className="posts__grid__block__content">
                            <p className="fs-18 fw5 lh-120">{value.name}</p>
                            <p className="fs-14 fw3 lh-120">
                              {value.description}
                            </p>
                          </div>
                          <Link
                            className="btn btn--blue fs-20 fw5"
                            to={`/posts/${value.slug}`}
                          >
                            Прочитать
                          </Link>
                        </div>
                      ))}
                </div>
              </>
            )}
            {RadioType === "Отчеты по сборам" && post.posts.length === 0 && (
              <div className="retreat">
                <h2 className="fs-32 fw7">
                  Отчетов у данного волонтера не найдено
                </h2>
              </div>
            )}
            {RadioType === "Сборы" && post.collections.length === 0 && (
              <div className="retreat">
                <h2 className="fs-32 fw7">
                  Сборов у данного волонтера не найдено
                </h2>
              </div>
            )}
            {RadioType === "Записи волонтера" && post.blogs.length === 0 && (
              <div className="retreat">
                <h2 className="fs-32 fw7">
                  Записей у данного волонтера не найдено
                </h2>
              </div>
            )}
            {RadioType === "Записи волонтера" && (
              <div className="retreat blog__grid">
                {post.blogs.map((value, index) => (
                  <div className="blog__grid__block" key={index}>
                    {value.pinned === 1 && (
                      <h6 className="fs-14 fw7">Закрепленно</h6>
                    )}
                    <div className="blog__grid__block__vol">
                      <img src={api_url + post.avatar} alt={post.name} />
                      <p className="fs-14 fw3">{post.name}</p>
                    </div>
                    <div className="blog__grid__block__content">
                      {value.images && value.images.length > 0 && (
                        <div className="blog__grid__block__content__img">
                          <FancyBox
                            options={{
                              Carousel: {
                                infinite: false,
                              },
                            }}
                          >
                            {value.images.map((image, index) => (
                              <a
                                data-fancybox="gallery"
                                key={index}
                                href={api_url + image}
                              >
                                <img src={api_url + image} alt={value.name} />
                              </a>
                            ))}
                          </FancyBox>
                        </div>
                      )}
                      <p className="fs-18 fw5 lh-120">{value.name}</p>
                      <p
                        className="fs-14 fw3 lh-120"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {value.text}
                      </p>
                    </div>
                    {value.link &&
                      value.link.length > 0 &&
                      value.link_name &&
                      value.link_name.length > 0 && (
                        <a
                          href={value.link}
                          className="fs-20 fw5 btn btn--blue"
                        >
                          {value.link_name}
                        </a>
                      )}
                  </div>
                ))}
              </div>
            )}
          </section>
        </>
      )}
    </>
  );
};
