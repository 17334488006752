// import "./App.css";
import "./assets/styles/style.css";
import { Header } from "./sections/header";
import { Footer } from "./sections/footer";
import { Homepage } from "./sections/homepage";
import { Secret } from "./sections/secret";
import "./assets/styles/style.scss";
import "./assets/styles/typography.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import { AboutPage } from "./sections/aboutPage";
import { Posts } from "./sections/posts";
import { Post } from "./sections/postPage";
import { Collection } from "./sections/collectionPage";
import { Volunteer } from "./sections/volunteerPage";
import { Additional } from "./sections/Additional";
import { Collections } from "./sections/collections";
import { useEffect } from "react";
import { Privacy } from "./sections/privacy";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/posts/:slug" element={<Post />} />
          <Route path="/collections/:id" element={<Collection />} />
          <Route
            path="/collections"
            element={<Collections colTypePage="Collections" />}
          />
          <Route path="/volunteers/:id" element={<Volunteer />} />
          <Route
            path="/volunteers"
            element={<Collections colTypePage="Volunteers" />}
          />

          <Route path="/information" element={<Additional />} />
          <Route path="/404" element={<Homepage />} />
          <Route path="/secret" element={<Secret />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<Homepage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
