import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { api_url } from "../data";
import { Loader } from "./components/loader";
import toast, { Toaster } from "react-hot-toast";
import { useClipboard } from "use-clipboard-copy";

export const Collection = () => {
  const clipboard = useClipboard();
  const navigate = useNavigate();
  function notify(text) {
    toast(`"${text}" было скопировано в буфер обмена!`);
  }

  const [isTop, setIsTop] = useState(false);
  const isSmallScreen = window.matchMedia("(min-width: 1140px)").matches;

  useEffect(() => {
    setIsTop(!isSmallScreen);
  }, [isSmallScreen]);

  const location = useLocation();

  const [post, setPost] = useState(null);

  useEffect(() => {
    setIsActive(true);
    setPost(null);
    fetch(`${api_url}/api${location.pathname}`)
      .then((response) => response.json())
      .then((data) => {
        setPost(data);
      })
      .catch((error) => {
        navigate("/404");
        console.error(error);
      });
    // eslint-disable-next-line
  }, [location.pathname]);

  function datesEnd(date) {
    const now = new Date();
    const endDate = new Date(date);
    const diffTime = Math.abs(
      Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
    );
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

    function daysCase(diffDays) {
      if (diffDays % 10 === 1) {
        return `Остался ${diffDays} день`;
      } else if (diffDays % 10 >= 2 && diffDays % 10 <= 4) {
        return `Осталось ${diffDays} дня`;
      } else {
        return `Осталось ${diffDays} дней`;
      }
    }

    if (now > endDate) {
      return "Завершено";
    } else {
      return `${daysCase(diffDays)}`;
    }
  }

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsActive(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [post]);

  function getDate(date) {
    if (date?.length > 0) {
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    } else {
      return "";
    }
  }

  function copyToClipboard(text) {
    clipboard.copy(text);
    notify(text);
  }

  return (
    <>
      <Toaster position={isTop ? "top-center" : "bottom-right"} />
      <Loader active={isActive} />
      {post && (
        <>
          <section className="wrapper retreat retreat--double fs-28">
            <Breadcrumbs
              breadcrumbs={[
                { url: `collections`, name: "Сборы" },
                { url: `${post.id}`, name: post.name },
              ]}
            />
          </section>
          <section className="wrapper retreat collectionPage">
            <div className="collectionPage__main">
              <img src={api_url + post.image} alt={post.name} />
              <div className="collectionPage__main__dates">
                <p className="fs-18 fw7 lh-120">Сроки сбора</p>
                <p className="fs-16 fw3 lh-120">
                  Шкала показывает насколько процентов выполнен сбор, а также
                  указывает когда он завершится
                </p>
                <div className="collections__grid__block__progress">
                  <progress value={post.progress} max="100" />
                  <div className="collections__grid__block__progress__dates">
                    <p className="fs-20 fw6">{getDate(post.start_date)}</p>
                    <p className="fs-20 fw6">{getDate(post.end_date)}</p>
                  </div>
                </div>
              </div>
              <div className="collectionPage__main__info">
                <h1 className="fs-32 fw7 lh-120">{post.name}</h1>
                <p className="fs-14 fw3 btn btn--blue">
                  {datesEnd(post.end_date)}
                </p>
                {post.description && (
                  <>
                    <h2 className="fs-32 fw7 lh-120">Описание сбора</h2>
                    <p
                      className="fs-16 fw3 lh-120"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {post.description}
                    </p>
                  </>
                )}
                <Link
                  to={`/volunteers/${post.user.id}`}
                  className="fs-20 fw5 btn btn--blue volunteer__link"
                >
                  Связаться с волонтером
                </Link>
              </div>
            </div>
            <div className="collectionPage__volunteer retreat retreat--double">
              <p className="fs-14 fw7">Автор сбора</p>
              <div className="collectionPage__volunteer__info">
                <img src={api_url + post.user.avatar} alt={post.user.name} />
                <div className="collectionPage__volunteer__info__name">
                  <p className="fs-20 fw6 lh-120">{post.user.name}</p>
                  <p className="fs-14 fw3 lh-120">Аккредитованный волонтер</p>
                </div>
                <Link
                  className="btn btn--circle btn--blue"
                  to={`/volunteers/${post.user.id}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="24"
                    viewBox="0 0 16 24"
                    fill="none"
                  >
                    <path
                      d="M7.45224 14.0182C3.19051 14.4034 -0.0563382 18.0045 0.000239928 22.2832V22.5002C0.000239928 23.3286 0.671818 24.0002 1.50024 24.0002C2.32866 24.0002 3.00024 23.3286 3.00024 22.5002V22.2232C2.95515 19.5961 4.8939 17.3558 7.50024 17.0232C10.2515 16.7504 12.703 18.7596 12.9758 21.5108C12.9919 21.6735 13.0001 21.8368 13.0002 22.0002V22.5002C13.0002 23.3286 13.6718 24.0002 14.5002 24.0002C15.3286 24.0002 16.0002 23.3286 16.0002 22.5002V22.0002C15.9953 17.5769 12.4056 13.9951 7.9824 14C7.80554 14.0002 7.62872 14.0063 7.45224 14.0182Z"
                      fill="white"
                    />
                    <path
                      d="M8.00049 12C11.3142 12 14.0005 9.31369 14.0005 6C14.0005 2.68631 11.3142 0 8.00049 0C4.6868 0 2.00049 2.68631 2.00049 6C2.00377 9.31233 4.68816 11.9967 8.00049 12ZM8.00049 3C9.65733 3 11.0005 4.34316 11.0005 6C11.0005 7.65684 9.65733 9 8.00049 9C6.34364 9 5.00049 7.65684 5.00049 6C5.00049 4.34316 6.34364 3 8.00049 3Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="collectionPage__bills retreat retreat--double">
              <h2 className="fs-32 fw7">Реквизиты волонтера</h2>
              <div className="collectionPage__bills__grid scrollBar">
                {post.user.bills.map((row, index) => (
                  <div key={index} className="collectionPage__bills__grid__row">
                    {Object.keys(row.bill).map((key, index) => (
                      <div
                        key={index}
                        className="collectionPage__bills__grid__row__block"
                        onClick={() => {
                          copyToClipboard(row.bill[key].value);
                        }}
                      >
                        <p className="fs-20 fw4">{row.bill[key].caption}</p>
                        <p className="fs-24 fw3">{row.bill[key].value}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div className="collectionPage__other retreat retreat--double">
              {post.user.collections.length > 1 && (
                <h2 className="fs-32 fw7">Другие cборы от волонтера</h2>
              )}
              <div className="collectionPage__other__flex scrollBar">
                {post.user.collections
                  .filter((val) => val.id !== post.id)
                  .map((value, index) => (
                    <div className="collections__grid__block" key={index}>
                      <div
                        className="collections__grid__block__img"
                        style={{
                          backgroundImage: `url(${api_url + value.image})`,
                        }}
                      ></div>
                      <div className="collections__grid__block__volunteer">
                        <img src={api_url + post.user.avatar} alt="" />
                        <p className="fs-14 fw3 lh-120">{post.user.name}</p>
                      </div>
                      <h5 className="fs-18 fw5">{value.name}</h5>
                      <p className="fs-14 fw3">{value.description}</p>
                      <div className="collections__grid__block__progress">
                        <progress value={value.progress} max="100" />
                        <div className="collections__grid__block__progress__dates">
                          <p className="fs-14 fw3">
                            {getDate(value.start_date)}
                          </p>
                          <p className="fs-14 fw3">
                            {getDate(value.start_date)}
                          </p>
                        </div>
                      </div>
                      <div className="collections__grid__block__links">
                        <Link
                          className="btn btn--blue"
                          to={`/collections/${value.id}`}
                        >
                          Перейти на сбор
                        </Link>
                        <Link
                          className="btn btn--circle btn--blue"
                          to={`/volunteers/${value.id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="24"
                            viewBox="0 0 16 24"
                            fill="none"
                          >
                            <path
                              d="M7.45224 14.0182C3.19051 14.4034 -0.0563382 18.0045 0.000239928 22.2832V22.5002C0.000239928 23.3286 0.671818 24.0002 1.50024 24.0002C2.32866 24.0002 3.00024 23.3286 3.00024 22.5002V22.2232C2.95515 19.5961 4.8939 17.3558 7.50024 17.0232C10.2515 16.7504 12.703 18.7596 12.9758 21.5108C12.9919 21.6735 13.0001 21.8368 13.0002 22.0002V22.5002C13.0002 23.3286 13.6718 24.0002 14.5002 24.0002C15.3286 24.0002 16.0002 23.3286 16.0002 22.5002V22.0002C15.9953 17.5769 12.4056 13.9951 7.9824 14C7.80554 14.0002 7.62872 14.0063 7.45224 14.0182Z"
                              fill="white"
                            />
                            <path
                              d="M8.00049 12C11.3142 12 14.0005 9.31369 14.0005 6C14.0005 2.68631 11.3142 0 8.00049 0C4.6868 0 2.00049 2.68631 2.00049 6C2.00377 9.31233 4.68816 11.9967 8.00049 12ZM8.00049 3C9.65733 3 11.0005 4.34316 11.0005 6C11.0005 7.65684 9.65733 9 8.00049 9C6.34364 9 5.00049 7.65684 5.00049 6C5.00049 4.34316 6.34364 3 8.00049 3Z"
                              fill="white"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
