import React, { useContext } from "react";
import { ReactReduxContext } from "react-redux";

import { Breadcrumbs } from "./components/Breadcrumbs";

export const Additional = () => {
  const { store } = useContext(ReactReduxContext);
  const state = store.getState();

  return (
    <>
      <section className="wrapper retreat retreat--double fs-28">
        <Breadcrumbs
          breadcrumbs={[
            { url: "usefull-information", name: "Полезная информация" },
          ]}
        />
        <div className="additional retreat">
          {state.data.data.additional.map((item, index) => (
            <div className="additional__block" key={index}>
              <h2 className="fs-20 fw6">{item.name}</h2>
              <a href={item.link} className="fs-20 fw4">
                {item.link_name}
              </a>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
