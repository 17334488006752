import React from "react";
import { Link } from "react-router-dom";

export const Breadcrumbs = ({ ...props }) => {
  let crumbs;
  if (props.breadcrumbs) {
    let path = "";
    crumbs = props.breadcrumbs.map((crumb, index) => {
      path += "/" + crumb.url;
      return (
        <Link
          className={`fs-20 ${
            props.breadcrumbs.length - 1 === index ? "fw6" : "fw4"
          }`}
          key={index}
          to={path}
        >
          {crumb.name}
        </Link>
      );
    });
  }
  return (
    <div className="breadcrumbs fs-20 fw4 scrollBar">
      <Link to={"/"}>Главная</Link>
      {crumbs}
    </div>
  );
};
